import Vue from 'vue';
import VuePortal from '@linusborg/vue-simple-portal';
import App from './App.vue';
import router from './router';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import store from './store';
import VueCookies from 'vue-cookies';
import VueScrollTo from 'vue-scrollto';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@mdi/font/css/materialdesignicons.css';
import vuetify from './plugins/vuetify';
import Vuetify from 'vuetify/lib';
import '@babel/polyfill';
import VueApexCharts from 'vue-apexcharts';
import AsyncComputed from 'vue-async-computed';

import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faUserSecret,
    faHome,
    faDraftingCompass,
    faToolbox,
    faMoneyBillAlt,
    faTrain,
    faTimes,
    faDatabase,
    faTimesCircle,
    faPhone,
    faCar,
    faCheck,
    faUsers,
    faFileAlt,
    faEuroSign
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(
    faUserSecret,
    faHome,
    faDraftingCompass,
    faToolbox,
    faTrain,
    faTimes,
    faDatabase,
    faTimesCircle,
    faPhone,
    faCar,
    faCheck,
    faUsers,
    faFileAlt,
    faMoneyBillAlt,
    faEuroSign
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuetify);
Vue.use(VueCookies);
Vue.use(VuePortal, {
    name: 'portal' // optional, use to rename component
});
Vue.use(VueScrollTo);

Vue.use(AsyncComputed);

Vue.config.productionTip = false;

export default new Vuetify({
    icons: {
        iconfont: 'mdi' // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                info: '#FFFFF'
            },
            dark: {
                info: '#FFFFF'
            }
        }
    }
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
